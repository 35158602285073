import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Fiz a escolha certa ao escolher o Fitclub e ao escolher o plano e programa certo já alcancei meu corpo ideal!",
    name: 'MATHEW HENDRICKSON ',
    status : 'EMPREENDEDORA'
  },
  {
    image: image2,
    review: 'O próprio fardo da dor deve ser seguido pelo adipismo do elitista. É conveniente que eles sejam impedidos de suportá-lo.',
    name: 'JOHN KEVIN ',
    status: 'TREINADOR'
  },
  {
    image : image3,
    review:' Se o cliente for muito inteligente, ele conseguirá alcançar o resultado desejado. O menos mordido é aquele prazer',
    name: 'FRANKLIN ',
    status: "CLINTE"
  }
];
